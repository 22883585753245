<template>
    <div>
        <h2>{{ $t("compta.impayes") }}</h2>

        <div class="box">
            <DateRangeV2
                :periodLabel="$t('date_range.jour')"
                :start.sync="start_date"
                :startDefault="start_default"
                :end.sync="end_date"
                persistId="impayes"
                :dateSelect=true
                @submit="loadUnpaid"
            />

            <b-button class="col-3" variant="primary" @click.prevent="display_all">{{ $t("action.display_unpaid") }}</b-button>

            <CustomTable
                id_table="tiers_impayes"
                ref="tiers_impayes"
                :items="duedatesByTiers"
                :busy.sync="table_busy"
                primaryKey="tiers.tiers_id"
                :hrefsRoutes="config_table_hrefs"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
            >
               
            </CustomTable>
        </div>

        <b-modal ref="modalRelance" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("invoice.relance_manuelle") }}
            </template>

            <div class="form-group">
                <label for="date">{{ $t('invoice.relance_date') }}</label>
                <e-datepicker v-model="date"></e-datepicker>
            </div>

            <div class="form-group">
                <label for="message">{{ $t('invoice.relance_message') }}</label>
                <input type="text" v-model="message" class="form-control">
            </div>

            <div class="col-8 m-auto">
                <b-button block pill variant="primary" @click.prevent="onSubmitRelance"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <ModalPrintInfosTiers ref="modal_print_infos_tiers"/>
        <ModalSendImpayes ref="modal_send_impayes" @submit="stopSpin"/>

    </div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
	import Config from "@/mixins/Config.js"
    import { EventBus } from 'EventBus'

    export default {
        name: "TiersListImpayes",
        mixins: [Config, TableAction, Invoice, Navigation, Payment],
        props: [],
        data () {
            return {
                duedatesByTiers: null,
                table_busy: true,
                ready: false,
                date: new Date(),
                processing: false,
                start_date: null,
                message: null,
                start_default: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: null,
                config_table_hrefs: {
                    'tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche_1',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    }
                },
                invoice_ids: null,
                rawColumnNames: ['tiers.tiers_rs'],
                events_tab: {
                    'TableAction::goToSetRelanceAllManuelle': (params) => {
                        this.setRelanceAllManuelle(params)
					},
                    'TableAction::goToSendAllInvoice': (params) => {
                        this.SendAllInvoice(params)
                    },
                    'TableAction::goToPrintInfos': (params) => {
                        this.openModalPrintInfos(params)
                    },
                },
            }
        },
        methods: {
            async loadUnpaid() {
				this.table_busy = true
                const duedates = await this.getUnpaidInvoices(this.start_date, this.end_date)
                this.duedatesByTiers = await this.formatUnpaid(duedates)
                this.table_busy = false
            },
            
            async formatUnpaid(duedates) {
                if(!duedates)
                {
                    return []
                }
                let allTiers = []
                let duedatesByTiers = []
                for (let i = 0; i < duedates.length; i++) {
                    const temp = duedates[i]
                    if (!allTiers.includes(temp.invoice.invoice_tiers)){
                        allTiers.push(temp.invoice.invoice_tiers)
                        duedatesByTiers.push({tiers: temp.invoice.tiers, invoice: [],invoice_ids:[], invoice_nb:0, balance:0})
                    }
                    let tiers = duedatesByTiers.find(x => x.tiers.tiers_id == temp.invoice.invoice_tiers)  
                    tiers.balance += temp.duedate_balance
                    tiers.invoice_nb += 1
                    tiers.invoice_ids.push(temp.invoice.invoice_id)
                    tiers.invoice.push(temp.invoice)
                    tiers.currents_accounts_balance = temp.currents_accounts_balance
                }


                // for( const tier of duedatesByTiers){
                //     tier.currents_accounts_balance = 0
                //     const currentsAccounts = duedates.balance.find(balance => balance.tiers_id == tier.tiers.tiers_id)
                //     if (currentsAccounts && currentsAccounts.balance){
                //         Object.keys(currentsAccounts.balance).forEach(cu => {
                //             tier.currents_accounts_balance += currentsAccounts.balance[cu].balance
                //         })
                //     }
                    
                //     tier.currents_accounts_balance = Math.round((tier.currents_accounts_balance / 100) * 100) / 100
                // }

                return duedatesByTiers
            },

            async setRelanceAllManuelle(tiers) {
                let invoice_ids = []
                for(const tier of tiers){
                    invoice_ids = invoice_ids.concat(tier.invoice_ids)
                }
                this.invoice_ids = invoice_ids.toString()
                this.$refs["modalRelance"].show()
            },

            async SendAllInvoice(tiers) {
                let params = {
                    invoice_ids: [],
                    author_ids: []
                }
                for(const tier of tiers){
                    for (const inv of tier.invoice){
                        params.author_ids.push(inv.author.tiers_id)
                    }
                    params.invoice_ids = params.invoice_ids.concat(tier.invoice_ids)
                }
                params.invoice_ids = params.invoice_ids.toString()

                let unique_ids = new Set(params.author_ids)
						if(unique_ids.size > 1) {
							this.infoToast("toast.model_choice")
						}
						if(unique_ids.size == 1) {
							this.openModalSendImpayes(params.invoice_ids, unique_ids)
						}
						else {
							this.callSendInvoice(params.invoice_ids)
						}
            },

            async display_all() {
                this.start_date = null
                this.end_date = null
                await this.loadUnpaid()
            },

            openModalSendImpayes(invoice_ids, unique_author_id) {
				let author_id = unique_author_id.values().next().value
				this.$refs.modal_send_impayes.openModal(invoice_ids, author_id)
			},

            async callSendInvoice(invoice_ids) {
				const model_type = "unpaid_invoice_notification"
                await this.sendMailInvoices(invoice_ids, model_type)
                EventBus.$emit("TableAction::stopSpin")
            },

			stopSpin() {
				EventBus.$emit("TableAction::stopSpin")
			},

            async onSubmitRelance() {
                await this.addRelanceManuelle(this.invoice_ids, this.date.toDateInputValue(), this.message)
                this.date = new Date()
                this.message = ""
                this.$refs["tiers_impayes"].unselectAll()
                this.$refs["modalRelance"].hide()
                await this.loadUnpaid()
            },

            openModalPrintInfos(params) {
				const tiers_ids = [...new Set(params.tiers_ids)]
				this.$refs.modal_print_infos_tiers.openModal(tiers_ids, params.nb_selected)
			},
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
            ModalPrintInfosTiers : () => import('@/components/Tiers/ModalPrintInfosTiers'),
            ModalSendImpayes : () => import('@/components/Tiers/ModalSendImpayes')
        }
    }
</script>
